import React, { useState, useEffect,Component } from 'react';
import { HashRouter, Route, Switch, BrowserRouter } from 'react-router-dom';
import './scss/style.scss';
import * as firebase from 'firebase/app';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Tabs = React.lazy(() => import('./views/base/tabs/Tabs'));
const Tables = React.lazy(() => import('./views/base/tables/Tables'));

class App extends Component {

  componentDidMount = () => {

    let firebaseConfig = {
      databaseURL: "https://lsoa-3eec9.firebaseio.com",
      apiKey: "AIzaSyBPRIKeJr7Xq_OCwwTtvkqyenV__7S-nUk",
      authDomain: "lsoa-3eec9.firebaseapp.com",
      projectId: "lsoa-3eec9",
      storageBucket: "lsoa-3eec9.appspot.com",
      messagingSenderId: "768388022154",
      appId: "1:768388022154:web:99e4006ab9fe237c8c9273",
      measurementId: "G-TT8TP38B5P"
    };
    // let firebaseConfig = {
    //   databaseURL: "https://aquacrusaders-digineeru.firebaseio.com",
    //   apiKey: "AIzaSyD24_n7CR3hdT5Z1NeEZoRMvHKL3HqC6o8",
    //   authDomain: "aquacrusaders-digineeru.firebaseapp.com",
    //   projectId: "aquacrusaders-digineeru",
    //   storageBucket: "aquacrusaders-digineeru.appspot.com",
    //   messagingSenderId: "526530117046",
    //   appId: "1:526530117046:web:1f1f7080f0735dbe58d517"
    // };

      let fireApp = firebase.initializeApp(firebaseConfig);

      // console.log("fireApp",fireApp);

  }

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" name="Login" render={props => <Login {...props}/>} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              {/* <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />  */}
               <Route path="/dashboard" name="Home" render={props => <TheLayout {...props}/>} />  
               <Route path="/consumptionPayload" name="Home" render={props => <TheLayout {...props}/>} /> 
               <Route path="/billing" name="Home" render={props => <TheLayout {...props}/>} /> 
               <Route path="/configuration" name="Home" render={props => <TheLayout {...props}/>} />
               <Route path="/inputwater" name="Home" render={props => <TheLayout {...props}/>} />
               <Route path="/outputwater" name="Home" render={props => <TheLayout {...props}/>} />
               <Route path="/freshwater" name="Home" render={props => <TheLayout {...props}/>} />
               <Route path="/treatedWater" name="Home" render={props => <TheLayout {...props}/>} />
               <Route path="/waterstorage" name="Home" render={props => <TheLayout {...props}/>} />
               <Route path="/alerts" name="Home" render={props => <TheLayout {...props}/>} />  
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
